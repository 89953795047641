
/*  Hero Section  */
.heroSection {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
}

/*  Markets Page  */
.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    position: relative;
    z-index: 1;
    width: 100%;
    background: #FFFFFF;

    padding-top: 0rem;
}

/* Intro Section */
.introSection {
    max-width: 200rem;
    margin: 0rem 0rem 0rem 0rem;
}

.introSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;

    margin: 16rem 15% 8rem 15%;
}

.introSectionTitle {
    color: #222222;
    font-size: var(--fontSizeTitleM);
    font-weight: var(--fontWeightXL);

    margin: 0rem 0rem 0rem 0rem;
}

.introSectionParagraph {
    color: #666666;
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);
    text-align: center;


    margin: 4rem 0rem 0rem 0rem;
}

/* Card Section */
.cardSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 200rem;
    background: #FFFFFF;

    padding-top: 2rem;
    margin: 0rem 0rem 0rem 0rem;
}

.cardSectionContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    margin: 0rem 2.5% 12rem 2.5%;
}

.card {
    margin: 1rem 1rem 1rem 1rem;
}

/* Contact Section */
.contactSection {
    max-width: 200rem;
    margin: 4rem 0rem 16rem 0rem;
}

/* Seperation Line */
.seperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0rem 0rem 12rem 0rem;
}

.seperation {
    padding: 0.2rem 0rem 0rem 0rem;
    background-color: var(--primary);
    background-image: linear-gradient(to bottom left, #FFFFFF, var(--primary), #FFFFFF);
    border-radius: 10rem;
    width: 80%;
}

@media screen and (max-width: 3840px) {
    .introSectionParagraph {
        color: #666666;
        font-size: 3.6rem;
        font-weight: var(--fontWeightM);
        text-align: center;
    
    
        margin: 4rem 0rem 0rem 0rem;
    }
}

@media screen and (max-width: 2560px) {
    .introSectionParagraph {
        color: #666666;
        font-size: 3.2rem;
        font-weight: var(--fontWeightM);
        text-align: center;
    
    
        margin: 4rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 1440px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 16rem 12.5% 8rem 12.5%;
    }

    /* Card Section */
    .cardSection {
        padding-top: 0rem;
    }

    .cardSectionContainer {
        margin: 0rem 2.5% 12rem 2.5%;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 12rem 0rem;
    }

    /* Seperation Line */
    .seperationContainer {
        margin: 0rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 1200px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 12rem 12.5% 6rem 12.5%;
    }

    .introSectionParagraph {
        font-size: var(--fontSizeTextM);

        margin: 3rem 0rem 0rem 0rem;
    }

    /* Card Section */
    .cardSectionContainer {
        margin: 0rem 2.5% 8rem 2.5%;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 8rem 0rem;
    }

    /* Seperation Line */
    .seperationContainer {
        margin: 0rem 0rem 8rem 0rem;
    }
}


@media screen and (max-width: 960px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 10rem 10% 5rem 10%;
    }

    /* Card Section */
    .cardSectionContainer {
        margin: 0rem 2.5% 6rem 2.5%;
    }
}


@media screen and (max-width: 840px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 8rem 7.5% 4rem 7.5%;
    }

    .introSectionParagraph {
        margin: 2rem 0rem 0rem 0rem;
    }

    /* Card Section */
    .cardSectionContainer {
        margin: 0rem 2.5% 4rem 2.5%;
    }
}


@media screen and (max-width: 720px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 8rem 5% 4rem 5%;
    }

    /* Seperation Line */
    .seperationContainer {
        margin: 0rem 0rem 6rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 8rem 5% 4rem 5%;
    }

    .introSectionTitle {
        font-size: var(--fontSizeTitleS);
        font-weight: var(--fontWeightXL);
    }

    .introSectionParagraph {
        font-size: var(--fontSizeTextM);
        margin: 2rem 0rem 0rem 0rem;
    }

    /* Card Section */
    .cardSectionContainer {
        margin: 0rem 2.5% 3rem 2.5%;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 6rem 0rem;
    }

    /* Seperation Line */
    .seperationContainer {
        margin: 0rem 0rem 4rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    /* Intro Section */
    .introSectionContainer {
        margin: 6rem 5% 4rem 5%;
    }

    .introSectionTitle {
        width: 100%;
        text-align: start;
    }

    .introSectionParagraph {
        text-align: start;
    }

    /* Card Section */
    .cardSectionContainer {
        margin: 0rem 2.5% 2rem 2.5%;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 4rem 0rem;
    }

    /* Seperation Line */
    .seperationContainer {
        margin: 0rem 0rem 2rem 0rem;
    }
}


@media screen and (max-width: 360px) {
    /* Intro Section */
    .introSectionTitle {
        font-size: var(--fontSizeTitleXS);
    }

    .introSectionParagraph {
        font-size: var(--fontSizeTextS);
    }

    /* Card Section */
    .cardSectionContainer {
        margin: 0rem 2.5% 0rem 2.5%;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 3rem 0rem;
    }

    /* Seperation Line */
    .seperationContainer {
        margin: 0rem 0rem 1rem 0rem;
    }
}
