/* Button Default */
.button {
    display: inline;
    padding: 0.8rem 2rem;
    border-radius: 5rem;
    outline: none;
    border: none;
    cursor: pointer;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-weight: 400;
}

/* Button Styles */
.buttonStyle1 {
    background-color: #FFFFFF;
    color: var(--primary);
    border: 0.2rem solid var(--primary);
    letter-spacing: var(--letterSpacingC);
}

.buttonStyle2 {
    background-color: #1D8DF4;
    color: white;
    border: 0.2rem solid #FFFFFF;
    letter-spacing: var(--letterSpacingC);
}

/* Button Sizes */
.buttonSizeExtraSmall {
    padding: 0.4rem 2rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.buttonSizeSmall {
    padding: 0.8rem 3rem;
    font-size: 1.8rem;
    font-weight: 400;
}

.buttonSizeMedium {
    padding: 0.8rem 4rem;
    font-size: 1.8rem;
    font-weight: 400;
}

.buttonSizeLarge {
    padding: 0.8rem 6rem;
    font-size: 2.4rem;
    font-weight: 500;
}

@media screen and (max-width: 3840px) {
    .buttonSizeExtraSmall {
        padding: 0.4rem 2rem;
        font-size: 2rem;
        font-weight: 600;
    }
    
    .buttonSizeSmall {
        padding: 0.8rem 3rem;
        font-size: 2.5rem;
        font-weight: 400;
    }
    
    .buttonSizeMedium {
        padding: 0.8rem 4rem;
        font-size: 3rem;
        font-weight: 400;
    }
    
    .buttonSizeLarge {
        padding: 0.8rem 6rem;
        font-size: 3.5rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 2560px) {
    .buttonSizeExtraSmall {
        padding: 0.4rem 2rem;
        font-size: 1.8rem;
        font-weight: 600;
    }
    
    .buttonSizeSmall {
        padding: 0.8rem 3rem;
        font-size: 2.2rem;
        font-weight: 400;
    }
    
    .buttonSizeMedium {
        padding: 0.8rem 4rem;
        font-size: 2.6rem;
        font-weight: 400;
    }
    
    .buttonSizeLarge {
        padding: 0.8rem 6rem;
        font-size: 3rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 1440px) {
    .buttonSizeExtraSmall {
        padding: 0.4rem 2rem;
        font-size: 1.8rem;
        font-weight: 600;
    }
    
    .buttonSizeSmall {
        padding: 0.8rem 3rem;
        font-size: 2.2rem;
        font-weight: 400;
    }
    
    .buttonSizeMedium {
        padding: 0.8rem 4rem;
        font-size: 1.8rem;
        font-weight: 400;
    }
    
    .buttonSizeLarge {
        padding: 0.8rem 6rem;
        font-size: 3rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 480px) {

    /* Button Sizes */
    .buttonSizeExtraSmall {
        padding: 0.3rem 1.7rem;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .buttonSizeMedium {
        padding: 0.6rem 3.2rem;
        font-size: 1.6rem;
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 360px) {
    /* Button Sizes */
    .buttonSizeExtraSmall {
        padding: 0.3rem 1.5rem;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .buttonSizeMedium {
        padding: 0.6rem 2.8rem;
        font-size: 1.4rem;
        font-weight: var(--fontWeightXL);
    }
}
