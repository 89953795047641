
/*  Hero Section  */
.heroSection {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
}

/*  About Us Page  */
.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    position: relative;
    z-index: 1;
    width: 100%;
    background: #FFFFFF;

    padding-top: 0rem;
}

/*  Intro Section  */
.introSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    width: 100%;
    max-width: 200rem;

    padding-top: 8rem;

    margin: 0rem 0rem 0rem 0rem;
}

.introSectionContainer {
    margin: 8rem 10% 8rem 10%;
}

.introSectionTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;

    color: #222222;
    font-size: var(--fontSizeTitleM);
    font-weight: var(--fontWeightXL);

    margin: 0rem 0rem 0rem 0rem;
}

.introSectionParagraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    color: #666666;
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);

    margin: 4rem 0rem 0rem 0rem;
}

/*  Banner Section  */
.bannerSection {
    background-color: var(--tertiary);
    background-image: linear-gradient(to left, #066bc9, var(--tertiary), #066bc9);
    width: 100%;
    margin: 10rem 0rem 0rem 0rem;
}

.bannerSectionTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bannerSectionTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;

    color: #FFFFFF;
    font-size: var(--fontSizeTitleM);
    font-weight: var(--fontWeightL);
    text-align: center;
    line-height: 170%;

    padding: 10rem 20% 10rem 20%;

    margin: 0rem 0rem 0rem 0rem;
}

/*  Market Section  */
.marketSection {
    margin: 4rem 0rem 0rem 0rem;

    max-width: 200rem;
}

.marketSectionContainer {
    background-color: #FFFFFF;

    padding-top: 4rem;
    margin: 0rem 0rem 0rem 0rem;
}

.marketSection1 {
    padding: 6rem 0rem 10rem 0rem;
}

.marketSection2 {
    margin: 12rem 0rem 10rem 0rem;
    padding: 0rem 0rem 0rem 0rem;
}

/* Contact Section */
.contactSection {
    max-width: 200rem;
    margin: 14rem 0rem 16rem 0rem;
}

/* Seperation Line */
.seperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 0rem 0rem 0rem 0rem;
}

.seperation {
    padding: 0.2rem 0rem 0rem 0rem;
    background-color: var(--primary);
    background-image: linear-gradient(to bottom left, #FFFFFF, var(--primary), #FFFFFF);
    border-radius: 10rem;
    width: 80%;
}

@media screen and (max-width: 3840px) {
    .introSectionParagraph {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        color: #666666;
        font-size: 3.2rem;
        font-weight: var(--fontWeightM);
    
        margin: 4rem 0rem 0rem 0rem;
    }
}

@media screen and (max-width: 2560px) {
    .introSectionParagraph {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        color: #666666;
        font-size: 2.8rem;
        font-weight: var(--fontWeightM);
    
        margin: 4rem 0rem 0rem 0rem;
    }
}

@media screen and (max-width: 1440px) {
    /*  Intro Section  */
    .introSection {
        padding-top: 6rem;
    }

    .introSectionContainer {
        margin: 8rem 10% 8rem 10%;
    }

    /*  Banner Section  */
    .bannerSection {
        margin: 8rem 0rem 0rem 0rem;
    }

    .bannerSectionTitle {
        padding: 10rem 15% 10rem 15%;
    }

    /*  Market Section  */
    .marketSection {
        margin: 0rem 0rem 0rem 0rem;
    }

    .marketSection1 {
        padding: 6rem 0rem 10rem 0rem;
    }
    
    .marketSection2 {
        margin: 10rem 0rem 10rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 12rem 0rem 14rem 0rem;
    }
}


@media screen and (max-width: 1200px) {
    /*  Intro Section  */
    .introSection {
        padding-top: 6rem;
    }

    .introSectionContainer {
        margin: 6rem 10% 6rem 10%;
    }

    .introSectionParagraph {
        font-size: var(--fontSizeTextM);
    }

    /*  Banner Section  */
    .bannerSection {
        margin: 8rem 0rem 0rem 0rem;
    }
    
    .bannerSectionTitle {
        padding: 8rem 10% 8rem 10%;
    }

    /*  Market Section  */
    .marketSection {
        margin: 0rem 0rem 0rem 0rem;
    }

    .marketSection1 {
        padding: 4rem 0rem 8rem 0rem;
    }
    
    .marketSection2 {
        margin: 6rem 0rem 8rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 10rem 0rem 12rem 0rem;
    }
}


@media screen and (max-width: 960px) {
    /*  Intro Section  */
    .introSection {
        padding-top: 6rem;
    }

    .introSectionContainer {
        margin: 4rem 7.5% 3rem 7.5%;
    }

    .introSectionParagraph {
        margin: 2rem 0rem 0rem 0rem;
    }

    /*  Banner Section  */
    .bannerSection {
        margin: 7rem 0rem 0rem 0rem;
    }

    .bannerSectionTitle {
        font-size: var(--fontSizeTitleS);

        padding: 6rem 10% 6rem 10%;
    }

    /*  Market Section  */
    .marketSection1 {
        padding: 2rem 0rem 6rem 0rem;
    }
    
    .marketSection2 {
        margin: 4rem 0rem 6rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 10rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 840px) {
    /*  Intro Section  */
    .introSection {
        padding-top: 6rem;
    }

    .introSectionContainer {
        margin: 3rem 7.5% 2rem 7.5%;
    }

    .introSectionParagraph {
        margin: 2rem 0rem 0rem 0rem;
    }

    /*  Banner Section  */
    .bannerSection {
        margin: 6rem 0rem 0rem 0rem;
    }

    .bannerSectionTitle {
        padding: 6rem 5% 6rem 5%;
    }

    /*  Market Section  */
    .marketSection1 {
        padding: 1rem 0rem 5rem 0rem;
    }
    
    .marketSection2 {
        margin: 3rem 0rem 5rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 6rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    /*  Intro Section  */
    .introSectionContainer {
        margin: 3rem 5% 2rem 5%;
    }

    /*  Banner Section  */
    .bannerSectionTitle {
        padding: 4rem 10% 4rem 10%;
    }
}


@media screen and (max-width: 600px) {
    /*  Intro Section  */
    .introSectionContainer {
        margin: 2rem 5% 0rem 5%;
    }

    .introSectionTitle {
        font-size: var(--fontSizeTitleS);
    }

    /*  Banner Section  */
    .bannerSectionTitle {
        font-size: var(--fontSizeTitleXS);

        padding: 4rem 15% 4rem 15%;
    }

    /* Contact Section */
    .contactSection {
        margin: 5rem 0rem 8rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    /*  Intro Section  */
    .introSectionContainer {
        margin: 0rem 5% 0rem 5%;
    }

    /*  Banner Section  */
    .bannerSection {
        margin: 5rem 0rem 0rem 0rem;
    }

    .bannerSectionTitle {
        padding: 4rem 5% 4rem 5%;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 6rem 0rem;
    }
}


@media screen and (max-width: 360px) {
    /*  Intro Section  */
    .introSectionContainer {
        margin: 0rem 5% 0rem 5%;
    }

    .introSectionTitle {
        font-size: var(--fontSizeTitleXS);
    }

    .introSectionParagraph {
        font-size: var(--fontSizeTextS);
    }

    /*  Banner Section  */
    .bannerSection {
        margin: 4rem 0rem 0rem 0rem;
    }

    .bannerSectionTitle {
        padding: 4rem 2.5% 4rem 2.5%;
    }

    /*  Market Section  */
    .marketSection1 {
        padding: 0rem 0rem 3rem 0rem;
    }
    
    .marketSection2 {
        margin: 2rem 0rem 3rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 1rem 0rem 4rem 0rem;
    }
}