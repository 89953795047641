/* Index Page */
.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    z-index: 1;
    width: 100%;
    background: #FFFFFF;

    padding-top: 8rem;
}

.sectionOverview {
    margin: 2rem 0rem 12rem 0rem;
}

.sectionSoftware {
    margin: 8rem 0rem 12rem 0rem;
}

.sectionCommunications {
    margin: 8rem 0rem 12rem 0rem;
}

/* Contact Section */
.contactSection {
    max-width: 200rem;
    margin: 14rem 0rem 16rem 0rem;
}

/* Seperation Line */
.seperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 200rem;
    margin: 0rem 0rem 0rem 0rem;
}

.seperation {
    padding: 0.2rem 0rem 0rem 0rem;
    background-color: var(--primary);
    background-image: linear-gradient(to bottom left, #FFFFFF, var(--primary), #FFFFFF);
    border-radius: 10rem;
    width: 80%;
}


@media screen and (max-width: 1440px) {
    /* Index Page */
    .sectionOverview {
        margin: 0rem 0rem 10rem 0rem;
    }

    .sectionSoftware {
        margin: 6rem 0rem 10rem 0rem;
    }

    .sectionCommunications {
        margin: 6rem 0rem 10rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 12rem 0rem 14rem 0rem;
    }
}


@media screen and (max-width: 1200px) {
    /* Index Page */
    .sectionContainer {
        padding-top: 6rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 10rem 0rem 12rem 0rem;
    }
}


@media screen and (max-width: 960px) {
    /* Index Page */
    .sectionContainer {
        padding-top: 5rem;
    }

    .sectionOverview {
        margin: 0rem 0rem 8rem 0rem;
    }

    .sectionSoftware {
        margin: 4rem 0rem 8rem 0rem;
    }

    .sectionCommunications {
        margin: 4rem 0rem 8rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 10rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 840px) {
    /* Index Page */
    .sectionOverview {
        margin: 0rem 0rem 10rem 0rem;
    }

    .sectionSoftware {
        margin: 5rem 0rem 10rem 0rem;
    }

    .sectionCommunications {
        margin: 5rem 0rem 10rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 8rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    /* Index Page */
    .sectionContainer {
        padding-top: 4rem;
    }

    .sectionOverview {
        margin: 0rem 0rem 8rem 0rem;
    }

    .sectionSoftware {
        margin: 4rem 0rem 8rem 0rem;
    }

    .sectionCommunications {
        margin: 4rem 0rem 8rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 6rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    /* Index Page */
    .sectionContainer {
        padding-top: 5rem;
    }
}


@media screen and (max-width: 480px) {
    /* Index Page */
    .sectionContainer {
        padding-top: 6rem;
    }

    .sectionOverview {
        margin: 0rem 0rem 6rem 0rem;
    }

    .sectionSoftware {
        margin: 4rem 0rem 6rem 0rem;
    }

    .sectionCommunications {
        margin: 4rem 0rem 6rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 4rem 0rem 6rem 0rem;
    }
}


@media screen and (max-width: 360px) {
    /* Index Page */
    .sectionContainer {
        padding-top: 5rem;
    }

    .sectionOverview {
        margin: 0rem 0rem 5rem 0rem;
    }

    .sectionSoftware {
        margin: 3rem 0rem 5rem 0rem;
    }

    .sectionCommunications {
        margin: 3rem 0rem 5rem 0rem;
    }

    /* Contact Section */
    .contactSection {
        margin: 1rem 0rem 3rem 0rem;
    }
}
