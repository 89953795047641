
.card {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    width: 65rem;
    height: 40rem;
    
    background-image: url('../../assets/images/hero_circle_large.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    padding: 1rem;
    background-color: #ffffff;
    border: 0.1rem solid #dadada;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0.2rem 0.2rem 1rem #999999;
    -moz-box-shadow: 0.2rem 0.2rem 1rem #999999;
    box-shadow: 0.2rem 0.2rem 1rem #999999;
    transition: all 0.2s ease-in;
    cursor: pointer;
}

.card:hover {
    transform: translateY(-0.2%);
    -webkit-box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    -moz-box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    cursor: default;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;

    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 10rem;
}

.title {
    color:white;
    font-size: var(--fontSizeTitleS);
    font-weight: 1000;

    padding-left: 0.5rem;
    margin: 0rem 0rem 0rem 0rem;
    width: 90%;
    box-shadow:
    inset 0 0 50px #1D8DF4,      /* inner white */
    inset 20px 0 80px ,   /* inner left magenta short */
    inset -20px 0 80px #1D8DF4,  /* inner right cyan short */
    inset 20px 0 300px #1D8DF4,  /* inner left magenta broad */
    inset -20px 0 300px #1D8DF4, /* inner right cyan broad */
    0 0 5px #1D8DF4,            /* outer white */
    5px 0 5px #1D8DF4,        /* outer left magenta */
    0px 0 5px #1D8DF4
}

.description {
    color:white;
    font-size: var(--fontSizeTextS);
    font-weight: 1000;
    letter-spacing: var(--letterSpacingC);
    
    font-size-adjust: initial;
    padding-left: 0.5rem;
    margin: 0rem 0rem 0rem 0rem;
    width: 90%;
    box-shadow:
    inset 0 0 50px #1D8DF4,      /* inner white */
    inset 20px 0 80px ,   /* inner left magenta short */
    inset -20px 0 80px #1D8DF4,  /* inner right cyan short */
    inset 20px 0 300px #1D8DF4,  /* inner left magenta broad */
    inset -20px 0 300px #1D8DF4, /* inner right cyan broad */
    0 0 5px #1D8DF4,            /* outer white */
    5px 0 5px #1D8DF4,        /* outer left magenta */
    0px 0 5px #1D8DF4
}

.button {
    margin: 1rem 0rem 1rem 0rem;
}



@media screen and (max-width: 3840px) {
    .card {
        width: 62rem;
        height: 32.5rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 1.75rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1.25rem;
        font-weight: var(--fontWeightL);
    }
}

@media screen and (max-width: 2560px) {
    .card {
        width: 62rem;
        height: 32.5rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 1.75rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1.25rem;
        font-weight: var(--fontWeightL);
    }
}

@media screen and (max-width: 1440px) {
    .card {
        width: 62rem;
        height: 32.5rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 1.75rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1.25rem;
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 1200px) {
    .card {
        width: 55rem;
        height: 29.8rem;
    
        padding: 1rem;

        border-radius: 1rem;
    }
    
    .title {
        font-size: 1.75rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1.25rem;
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 1080px) {
    .card {
        width: 50rem;
        height: 27rem;

        padding: 1rem;
    }
    
    .title {
        font-size: 2rem;
        font-weight: var(--fontWeight2XL);
        width: auto;
    }
    
    .description {
        font-size: 1.2rem;
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingB);
        width: auto;
    }
}


@media screen and (max-width: 960px) {
    .card {
        width: 70rem;
        height: 37.2rem;
    
        padding: 1rem;

        border-radius: 1.5rem;
    }
    
    .title {
        font-size: 2rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1.2rem;
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 720px) {
    .card {
        width: 63rem;
        height: 33rem;
    
        padding: 1rem;
    }

    .container {
        padding-left: 0rem;
    }
    
    .title {
        font-size: 2rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1.2rem;
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
    }

    .button {
        margin: 1rem 0rem 0.5rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    .card {
        width: 50rem;
        height: 28.2rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 2rem;
        font-weight: var(--fontWeight2XL);
        padding-left: 0rem;
    }
    
    .description {
        font-size: 1.2rem;
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightL);
        padding-left: 0rem;
    }

    .button {
        margin: 1rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    .card {
        width: 46rem;
        height: 25rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 1.5rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 1rem;
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 420px) {
    .card {
        width: 38rem;
        height: 20.9rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 1.25rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 0.9rem;
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingA);
    }
}


@media screen and (max-width: 360px) {
    .card {
        width: 33rem;
        height: 18.5rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: 1.1rem;
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: 0.75rem;
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingA);
    }
}