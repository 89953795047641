/*  Hero Section  */
.heroSection {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
}

/*  Communications Page  */
.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    position: relative;
    z-index: 1;
    width: 100%;
    background: #FFFFFF;

    padding-top: 0rem;
}

/*  Sections  */
.section1 {
    max-width: 200rem;
    margin: 8rem 0rem 8rem 0rem;
}

.section2 {
    max-width: 200rem;
    margin: 8rem 0rem 8rem 0rem;
}

.section3 {
    max-width: 200rem;
    margin: 8rem 0rem 8rem 0rem;
}

/*  Video  */
.videoContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    margin: 4rem 0rem 8rem 0rem;
}

.videoContainer2 {
    position: relative;
    overflow: hidden;
    padding-top: 35.15625%;
    width: 62.5%;
    /*
    16:9 Aspect Ratio
    padding-top: 28.125% width: 50%
    padding-top: 35.15625% width: 62.5%
    padding-top: 42.1875% width: 75%
    padding-top: 56.25% width: 100%
    */
}

.iFrame {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0;
    left: 0;
    top: 0;
}

/*  Rescue Section  */
.rescueContainer {
    margin: 0rem 10% 0rem 10%;
}

.rescueContent {
    color: #666666;
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);
    text-align: left;
}

/*  Contact Section  */
.contactSection {
    max-width: 200rem;
    margin: 12rem 0rem 14rem 0rem;
}

/* Seperation Line */
.seperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 200rem;
    margin: 0rem 0rem 0rem 0rem;
}

.seperation {
    padding: 0.2rem 0rem 0rem 0rem;
    background-color: var(--primary);
    background-image: linear-gradient(to bottom left, #FFFFFF, var(--primary), #FFFFFF);
    border-radius: 10rem;
    width: 80%;
}

@media screen and (max-width: 3840px) {
    .rescueContent {
        color: #666666;
        font-size: 3.2rem;
        font-weight: var(--fontWeightM);
        text-align: left;
    }
}

@media screen and (max-width: 2560px) {
    .rescueContent {
        color: #666666;
        font-size: 2.8rem;
        font-weight: var(--fontWeightM);
        text-align: left;
    }
}


@media screen and (max-width: 1440px) {
    /*  Sections  */
    .section1 {
        margin: 7rem 0rem 6rem 0rem;
    }

    .section2 {
        margin: 6rem 0rem 6rem 0rem;
    }

    .section3 {
        margin: 6rem 0rem 6rem 0rem;
    }

    /*  Video  */
    .videoContainer1 {
        margin: 3rem 0rem 6rem 0rem;
    }

    /*  Rescue Section  */
    .rescueContainer {
        margin: 0rem 10% 0rem 10%;
    }

    /*  Contact Section  */
    .contactSection {
        margin: 10rem 0rem 12rem 0rem;
    }
}


@media screen and (max-width: 1200px) {
    /*  Sections  */
    .section1 {
        margin: 6rem 0rem 5rem 0rem;
    }

    .section2 {
        margin: 5rem 0rem 5rem 0rem;
    }

    .section3 {
        margin: 5rem 0rem 5rem 0rem;
    }

    /*  Video  */
    .videoContainer1 {
        margin: 2rem 0rem 5rem 0rem;
    }

    /*  Rescue Section  */
    .rescueContainer {
        margin: 0rem 10% 0rem 10%;
    }

    .rescueContent {
        font-size: var(--fontSizeTextM);
    }

    /*  Contact Section  */
    .contactSection {
        margin: 8rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 960px) {
    /*  Sections  */
    .section1 {
        margin: 5rem 0rem 4rem 0rem;
    }

    .section2 {
        margin: 4rem 0rem 4rem 0rem;
    }

    .section3 {
        margin: 4rem 0rem 4rem 0rem;
    }

    /*  Video  */
    .videoContainer1 {
        margin: 1.5rem 0rem 4rem 0rem;
    }

    /*  Rescue Section  */
    .rescueContainer {
        margin: 0rem 7.5% 0rem 7.5%;
    }
}


@media screen and (max-width: 720px) {
    /*  Sections  */
    .section1 {
        margin: 4rem 0rem 3rem 0rem;
    }

    .section2 {
        margin: 3rem 0rem 3rem 0rem;
    }

    .section3 {
        margin: 3rem 0rem 3rem 0rem;
    }

    /*  Video  */
    .videoContainer1 {
        margin: 1.5rem 0rem 3.5rem 0rem;
    }

    .videoContainer2 {
        padding-top: 42.1875%;
        width: 75%;
        /*
        16:9 Aspect Ratio
        padding-top: 28.125% width: 50%
        padding-top: 35.15625% width: 62.5%
        padding-top: 42.1875% width: 75%
        padding-top: 56.25% width: 100%
        */
    }

    /*  Rescue Section  */
    .rescueContainer {
        margin: 0rem 5% 0rem 5%;
    }

    /*  Contact Section  */
    .contactSection {
        margin: 6rem 0rem 10rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    /*  Video  */
    .videoContainer1 {
        margin: 1rem 0rem 2rem 0rem;
    }

    .videoContainer2 {
        padding-top: 49.21875%;
        width: 87.5%;
        /*
        16:9 Aspect Ratio
        padding-top: 28.125% width: 50%
        padding-top: 35.15625% width: 62.5%
        padding-top: 42.1875% width: 75%
        padding-top: 49.21875% width: 87.5%
        padding-top: 56.25% width: 100%
        */
    }
    /*  Contact Section  */
    .contactSection {
        margin: 4rem 0rem 6rem 0rem;
    }
}


@media screen and (max-width: 360px) {
    /*  Rescue Section  */
    .rescueContent {
        font-size: var(--fontSizeTextS);
    }

    /*  Contact Section  */
    .contactSection {
        margin: 2rem 0rem 4rem 0rem;
    }
}
