.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: var(--tertiary);
    background-image: linear-gradient(to left, #066bc9, var(--tertiary), #066bc9);
    color: #ffffff;
    width: 100%;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: var(--fontSizeTextM);
    font-style: normal;
    font-weight: var(--fontWeightS);
}

.container {
    display: grid;
    justify-content: center;
    align-content: center;

    width: 60%;
    max-width: 200rem;

    grid-template-columns: 0.5fr 1fr 1.5fr;
    grid-template-rows: auto auto auto auto;
    grid-column-gap: 5%;

    z-index: 1;

    margin: 0rem 0rem 0rem 0rem;
}

.ubitech {
    grid-column: 1 / 3;
    grid-row: 1;

    font-size: var(--fontSizeTitle2XL);
    font-weight: var(--fontWeightL);

    margin: 4rem 0rem 0rem 10rem;
}

.navTitleDiv {
    grid-column: 1 / 3;
    grid-row: 2;

    margin: 2rem 0rem 0rem 10rem;
}

.navTitle {
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);
}

.navLinks1 {
    grid-column: 1;
    grid-row: 3;

    margin: 1rem 0rem 0rem 11rem;
}

.navLinks2 {
    grid-column: 2;
    grid-row: 3;

    margin: 1rem 0rem 0rem 4rem;
}

.list {
    list-style-type: none;
    text-decoration: none;
    margin: 0rem;
    padding: 0rem;
}

.link {
    text-decoration: none;
    font-size: var(--fontSizeText2XL);
    font-style: normal;
    font-weight: var(--fontWeightM);
}

.link2 {
    font-size: var(--fontSizeTextM);
    font-style: normal;
    font-weight: var(--fontWeightS);
}

.addressDiv {
    grid-column: 3;
    grid-row: 2;

    margin: 2rem 0rem 0rem 12rem;
}

.addressTitle {
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);
}

.addressContainer {
    grid-column: 3;
    grid-row: 3;

    list-style-type: none;
    text-decoration: none;

    margin: 1rem 0rem 0rem 13rem;
}

.address {
    font-size: var(--fontSizeTextM);
    font-style: normal;
    font-weight: var(--fontWeightS);

    padding: 0.35rem 0rem;
}

.copyright {
    grid-column: 1 / 4;
    grid-row: 4;

    text-align: center;
    font-weight: var(--fontWeightS);
    font-size: var(--fontSizeTextM);

    margin: 6rem 0rem 6rem 0rem;
}

@media screen and (max-width: 3840px) {
    .ubitech {
        grid-column: 1 / 3;
        grid-row: 1;
    
        font-size: 4.2rem;
        font-weight: var(--fontWeightL);
    
        margin: 4rem 0rem 0rem 10rem;
    }

    .navTitle {
        font-size: 3.8rem;
        font-weight: var(--fontWeightM);
    }

    .link {
        text-decoration: none;
        font-size: 3.5rem;
        font-style: normal;
        font-weight: var(--fontWeightM);
    }

    .addressTitle {
        font-size: 3.5rem;
        font-weight: var(--fontWeightM);
    }
    
    .address {
        font-size: 3.2rem;
        font-style: normal;
        font-weight: var(--fontWeightS);
    
        padding: 0.35rem 0rem;
    }

    .copyright {
        grid-column: 1 / 4;
        grid-row: 4;
    
        text-align: center;
        font-weight: var(--fontWeightS);
        font-size: 3rem;
    
        margin: 6rem 0rem 6rem 0rem;
    }
    
    
}


@media screen and (max-width: 2560px) {
    .ubitech {
        grid-column: 1 / 3;
        grid-row: 1;
    
        font-size: 4.2rem;
        font-weight: var(--fontWeightL);
    
        margin: 4rem 0rem 0rem 10rem;
    }

    .navTitle {
        font-size: 3.5rem;
        font-weight: var(--fontWeightM);
    }

    .link {
        text-decoration: none;
        font-size: 3.2rem;
        font-style: normal;
        font-weight: var(--fontWeightM);
    }

    .addressTitle {
        font-size: 3.5rem;
        font-weight: var(--fontWeightM);
    }
    
    .address {
        font-size: 3.2rem;
        font-style: normal;
        font-weight: var(--fontWeightS);
    
        padding: 0.35rem 0rem;
    }

    .copyright {
        grid-column: 1 / 4;
        grid-row: 4;
    
        text-align: center;
        font-weight: var(--fontWeightS);
        font-size: 3rem;
    
        margin: 6rem 0rem 6rem 0rem;
    }
    
}


@media screen and (max-width: 1440px) {
    .container {
        width: 80%;
        grid-column-gap: 10%;
    }

    .ubitech {
        margin: 4rem 0rem 0rem 6rem;
    }

    .navTitleDiv {
        margin: 2rem 0rem 0rem 6rem;
    }

    .navLinks1 {
        margin: 1rem 0rem 0rem 7rem;
    }
}


@media screen and (max-width: 1200px) {
    .container {
        width: 90%;
        grid-template-columns: 0.5fr 1fr 1.5fr;
        grid-column-gap: 7.5%;
    }
}


@media screen and (max-width: 960px) {
    .container {
        width: 95%;
        grid-template-columns: 0.5fr 0.75fr 1.5fr;
        grid-column-gap: 5%;
    }
}


@media screen and (max-width: 840px) {
    .container {
        width: 95%;
        grid-template-columns: 0.5fr 0.5fr 1.5fr;
        grid-column-gap: 2.5%;
    }
}


@media screen and (max-width: 720px) {
    .container {
        width: 80%;
    
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto auto auto;
        grid-column-gap: 5%;
    }

        
    .ubitech {
        grid-column: 1 / 3;
        grid-row: 1;

        font-size: var(--fontSizeTitleXL);
        font-weight: var(--fontWeightL);

        margin: 4rem 0rem 0rem 6rem;
    }

    .navTitleDiv {
        grid-column: 1 / 3;
        grid-row: 2;

        margin: 2rem 0rem 0rem 6rem;
    }

    .navTitle {
        font-size: var(--fontSizeTextL);
        font-weight: var(--fontWeightM);
    }

    .navLinks1 {
        grid-column: 1;
        grid-row: 3;

        margin: 1rem 0rem 0rem 7rem;
    }

    .navLinks2 {
        grid-column: 2;
        grid-row: 3;

        margin: 1rem 0rem 0rem 0rem;
    }

    .link {
        text-decoration: none;
        font-size: var(--fontSizeText2XL);
        font-style: normal;
        font-weight: var(--fontWeightM);
    }
    
    .link2 {
        font-size: var(--fontSizeTextM);
        font-style: normal;
        font-weight: var(--fontWeightS);
    }

    .addressDiv {
        grid-column: 1 / 3;
        grid-row: 4;

        margin: 3rem 0rem 0rem 6rem;
    }

    .addressTitle {
        font-size: var(--fontSizeTextL);
        font-weight: var(--fontWeightM);
    }

    .addressContainer {
        grid-column: 1 / 3;
        grid-row: 5;

        margin: 1rem 0rem 0rem 7rem;
    }

    .address {
        font-size: var(--fontSizeTextM);
        font-style: normal;
        font-weight: var(--fontWeightS);
    
        padding: 0.35rem 0rem;
    }

    .copyright {
        grid-column: 1 / 3;
        grid-row: 6;

        font-weight: var(--fontWeightS);
        font-size: var(--fontSizeTextM);
    
        margin: 5rem 0rem 5rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    .container {
        width: 90%;
    
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto auto auto;
        grid-column-gap: 5%;
    }
}


@media screen and (max-width: 480px) {
    .ubitech {
        font-size: var(--fontSizeTitleL);
        margin: 4rem 0rem 0rem 4rem;
    }

    .navTitleDiv {
        margin: 2rem 0rem 0rem 4rem;
    }

    .navTitle {
        font-size: var(--fontSizeTextM);
    }

    .navLinks1 {
        margin: 1rem 0rem 0rem 5rem;
    }

    .navLinks2 {
        margin: 1rem 0rem 0rem 0rem;
    }

    .addressDiv {
        margin: 2rem 0rem 0rem 4rem;
    }

    .addressTitle {
        font-size: var(--fontSizeTextM);
    }

    .addressContainer {
        margin: 1rem 0rem 0rem 5rem;
    }

    .address {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightS);
    }

    .copyright {
        font-size: var(--fontSizeTextS);
        margin: 4rem 0rem 4rem 0rem;
    }
}


@media screen and (max-width: 360px) {
    .container {
        width: 95%;
        grid-column-gap: 2.5%;
    }

    .ubitech {
        font-size: var(--fontSizeTitleM);
        margin: 2rem 0rem 0rem 4rem;
    }

    .navTitleDiv {
        margin: 2rem 0rem 0rem 4rem;
    }

    .navTitle {
        font-size: var(--fontSizeTextS);
    }

    .navLinks1 {
        margin: 1rem 0rem 0rem 5rem;
    }

    .navLinks2 {
        margin: 1rem 0rem 0rem 0rem;
    }

    .link {
        font-size: var(--fontSizeTextXL);
        font-weight: var(--fontWeightS);
    }

    .addressDiv {
        margin: 2rem 0rem 0rem 4rem;
    }

    .addressTitle {
        font-size: var(--fontSizeTextS);
    }

    .addressContainer {
        margin: 1rem 0rem 0rem 5rem;
    }

    .address {
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightS);
    }

    .copyright {
        font-size: var(--fontSizeTextXS);
        margin: 4rem 0rem 4rem 0rem;
    }
}
